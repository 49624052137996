import './video-responser.scss';

class VideoResponser {
    constructor ($video) {
        this.options = {
            $video,
            breakpoints: {
                default: {
                    src: $video.getAttribute('data-src')
                }
            }
        };

        // get a list of video switching points and links to the videos themselves
        $video.querySelectorAll('[data-src]').forEach(element => this.options.breakpoints[element.getAttribute('data-mw')] = { src: element.getAttribute('data-src') }); // eslint-disable-line
        $video.innerHTML = ''; // we clean up so that there is nothing superfluous

        // run the handler and track the change in screen width
        this.responseVideo(this.options);
        this.resizer();

        this.currentWindowWidth = window.innerWidth;
    }

    /** Function runs on resize  */
    resizer () {
        window.addEventListener('resize', () => {
            if (this.currentWindowWidth !== window.innerWidth) {
                this.responseVideo(this.options);
                this.currentWindowWidth = window.innerWidth;
            }
        });
    }

    /**
     * Change src value of video link to fit screen width
     *
     * @param {Object} options object with options
     */
    responseVideo (options) {
        const { $video, breakpoints } = options; // get options
        const widthNow = $video.getAttribute('data-width-now') || null;
        const maxBreakpoint = Math.max.apply(Math, Object.keys(breakpoints).filter(key => key <= document.body.clientWidth).map(Number));
        const nowBreakpoint = maxBreakpoint || 'default'; // choose either the maximum value, if not, then the default

        if (widthNow && widthNow === nowBreakpoint) return; // check if the video needs to be changed

        $video.setAttribute('data-width-now', nowBreakpoint);
        $video.src = breakpoints[nowBreakpoint].src;
    }
}

export { VideoResponser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$videoResponser = $context.querySelectorAll('[data-videoresponser="root"]');
        for (let i = 0; i < $$videoResponser.length; i++) {
            const $videoResponser = $$videoResponser[i];

            const videoResponserAPI = new VideoResponser($videoResponser);
            $videoResponser.API = videoResponserAPI;
        }
    }
});
